
.form-label-2{
font-family: 'Mulish';
font-style: normal;
font-weight: 400;
font-size: 2pc;
line-height: 25px;
letter-spacing: 0.3px;
color: rgba(0, 0, 0, 0.6);
margin: 8px 0px ;
text-transform: capitalize;
}
:root {
    --toastify-toast-width: auto !important;
    --toastify-toast-min-width:320px !important;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 37px !important;
    --toastify-toast-max-height: 800px;
}

body{
  background-color: #ffff !important;
}


.site-logo{
    width: 63.48px;
    height: 64px;
    margin-bottom: 32px;    
}



.password{
    border-right: 0px !important;
}

.Toastify__toast--success {
    border-radius: 16px !important;
    background: #000000 !important;
  }


.Toastify__toast--error {
    // border: 1px solid #ffffff !important;
    border-radius: 16px !important;
    background: #000000 !important;
  }



.Toastify__toast-body {
    color: hsl(0, 0%, 100%);
    font-size: 16px ;
    padding-left: 00px;
    line-height: 0px;
    padding: 0px;
    padding-top: 25px;
    width: 100%;
    font-weight: 400;
    border-radius: 50px !important;
    margin-left: 25px !important;
  } 
  .text-gray01{
    color: rgba(0, 0, 0, 0.3);
    ;
  }

  .my-model{
    padding: 50px 0px !important;
  }

  .TopButton{
    // margin-right: 36px;
    // margin-left: 36px;
    margin-top: 22px ;
  }

  .TopButton button{ 
    margin: 0px 20px;
    padding: 8px 16px;
  }

  .BackArrow{
    margin-right: 20px;
    margin-top: -5px ;
  }

  .nav-tabs a{
    color: rgba(0, 0, 0, 0.3) !important;
    padding-bottom: 10px !important;
    font-family: 'Mulish' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 23px !important;

  }

  .image-container{
    position: relative;
    display: inline-block;
  }
  .image-container img {
    display: block;
    max-width: 100%;
    cursor: pointer;
  }

  .image-container .video-duration {
    position: absolute;
    bottom: 10px; /* Adjust the positioning as per your needs */
    left: 125px; /* Adjust the positioning as per your needs */
    right: 5px;
    color: white;
    // border: solid white 1px ;
    background-color: #2f2f2f;
    border-radius: 5px;
    padding: 2px 4px;
    // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    font-size: 13px;
  }

  .image-container .delete-btn{
    position: absolute;
    bottom: 10px; /* Adjust the positioning as per your needs */
    left: 110px; /* Adjust the positioning as per your needs */
    right: 5px;
    color: white;
    border-radius: 5px;
    padding: 2px 4px;
    // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    font-size: 13px;
    opacity: 0;
  }


.image-container:hover .delete-btn{
  opacity: 1;
}



  .nav-tabs a.active{
    color: rgb(0, 0, 0) !important;
    border-bottom: 3px solid black !important;
    padding-bottom: 0px !important;

  }


  .video-duration{
   
    position: relative;
    right:0px;

  }

.brand-name{
width: 266px;
height: 19px;
font-family: 'Mulish';
font-style: normal;
font-weight: 600;
font-size: 15px;
line-height: 19px;
color: #000000;


flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
}

.brand-addedon{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 1;

  /* identical to box height */
  /* Content/Dark/Low Emp */

color: rgba(0, 0, 0, 0.3);
}

.brand-details{
  padding-top: 10px;
  padding-bottom:10px ;
}

.brand-image{
  border-radius: 10px;
  border: 1px solid rgba(181, 180, 180, 0.389);
}

.modal-10w{
  width: 757px !important;
  max-width: 600px !important;
  border-radius: 16px;
}

.item-title{
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 10px;
  /* identical to box height, or 160% */
  
  
  /* Content/Dark/Low Emp */
  
  color: rgba(0, 0, 0, 0.3);
}

.item-value{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  // line-height: 4px;
  /* identical to box height, or 160% */
  
  
  /* Content/Dark/High Emp */
  
  color: #000000;
  
}

.Details-Container{
  height: 500px;
  overflow: scroll;
}

.color-preview{
  width: 40px !important;
  height: 40px !important; 
}


.color-preview-3{
  width: 22px !important;
  height: 22px !important; 
  border: 1px solid #000000;
}

.text-h1-large{
  font-family: 'Mulish';
font-style: normal;
font-weight: 700;
font-size: 80px;
line-height: 100px;
text-align: center;
letter-spacing: 1px;
}

.text-title{
font-family: 'Mulish';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 30px;
letter-spacing: 0.3px;
color: #000000;
}

.bg-light-gray{
  padding: 32px;
  background: #F7F7F7;
  border-radius: 16px;
}

.form-label-1{
font-family: 'Mulish';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
letter-spacing: 0.3px;
color: rgba(0, 0, 0, 0.6);
margin: 8px 0px ;
text-transform: capitalize;
}

.form-input-1{
  padding: 9px;
  height: 52px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 8px;
}

.form-check-1{
  // padding: 24px 8px !important;
  background-color: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.16) !important;
  color: rgba(0, 0, 0, 0.16) !important;
}


.form-check-1.active{
  background-color: transparent !important;
  border: 1px solid rgba(0, 0, 0) !important;
  color: rgba(0, 0, 0) !important;
}

.hide-input{
  position: relative;
  z-index: 2;
  opacity: 0;
  // top:50px;
  // left:-61px;
  height: 100px;
  width: 100px;
  background-color: aqua;
}

.upload-box{
  z-index: 1;
  position: relative;
  top:-100px;
  // display: block;
  height: 140px;
  width: 100px;
  background: rgba(55, 81, 255, 0.08);
  border: 4px dashed rgba(55, 81, 255, 0.24);
  border-radius: 10px;
}

.upload-box .icon{
  padding: 60px 36px;
}

.image-preview{
  height: 110px;
  margin-right: 10px;
  margin-bottom: 20px;
  
}


.Input-file{
  // height: 150px;
  margin-right: 10px;
}


.image-preview img{
  // margin-right:px;
  border: 2px solid rgba(78, 78, 78, 0.24);
  width: 100px;
  height: 140px;
  border-radius: 10px;
}


.image-preview small {
  position: absolute;
  // right:0px
  background-color: #F7F7F7;
  width: 20px;
  height: 20px;
  border: 1px solid red;
  border-radius: 16px;
  width: 29px;
  height: 29px;
  padding: 4px 7px;
  margin-left: 67px;
  margin-top: 107px;
}
@media (max-width: 768px) {
  .Input-file {
    height: 50px; /* Adjust the height as needed for smaller screens */
    margin-right: 5px; /* Adjust the margin as needed for smaller screens */
  }

.upload-box{
  z-index: 1;
  position: relative;
  top:-100px;
  // display: block;
  height: 100px;
  width: 75px;
  background: rgba(55, 81, 255, 0.08);
  border: 1px dashed rgba(55, 81, 255, 0.24);
  border-radius: 6px;
}

.upload-box .icon{
  padding: 40px 20px;
}

.image-preview img{
  // margin-right:px;
  border: 1px solid rgba(78, 78, 78, 0.24);
  // width: 75px;
  // height: 100px;
  border-radius: 6px;
}

.image-preview small {
  position: absolute;
  // right:0px
  background-color: #F7F7F7;
  width: 20px;
  height: 20px;
  border: 1px solid red;
  border-radius: 16px;
  width: 29px;
  height: 29px;
  padding: 4px 7px;
  // margin-left: 30px;
  // margin-top: 50px;
}

.image-preview{
  height: 110px;
  margin-right: 0px;
  margin-bottom: 40px;
  
}
}



.brand-preview{
  .name{
    text-transform: capitalize;
    margin-top:20px ;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.3px;
    color: rgba(0, 0, 0, 0.6);
  }
  .item{
    text-transform: capitalize;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.3px;
    color: rgba(0, 0, 0, 0.6);
    
  }
  .bynow{
    border-radius: 0px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */
    
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    

  }

}

.TestClass{
  // width: fit-content !important;
  border-radius: 16px !important;
  padding: 20px !important;
  text-align: center;
  font-weight: 500;
  height: 280px;
  width: 280px;
  color: #000000;
}

.counter-name{

font-family: 'Mulish';
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 50px;
text-align: center;
letter-spacing: 0.4px;

/* Content/Dark/Low Emp */

color: rgba(0, 0, 0, 0.3);


}

.Counter-count{
  font-family: 'Mulish';
font-style: normal;
font-weight: 700;
font-size: 80px;
line-height: 100px;
text-align: center;
letter-spacing: 1px;

/* Content/Dark/High Emp */

color: #000000;
}


.ReactLoader{
    position: absolute;
    height: 1050px;
    z-index: 10;
    // width: max-content;
    width: -webkit-fill-available;
    
    background: #FFFFFF;
}



.check-input-1{
  border: 1px solid #696969 !important;
  color: #1e1e1e !important;

  
}